<template>
  <div class="clearBox">
    <ayl-berad-nav :nav="nav"></ayl-berad-nav>
    <div class="content-box" style="padding-top: 24px">
      <div class="content-main">
        <!-- 标题 -->
        <div>
          <span class="title">{{nav[0].name}}</span>
        </div>
        <!-- 左侧非地图部分 -->
        <div class="content-main-left">
          <!-- 标段输入项 -->
          <div class="content-main-hw" style="padding-top: 20px">
            <el-form
              :model="form"
              :rules="rules"
              ref="form"
              :inline="true"
              label-position="right"
              label-width="170px"
            >
              <el-form-item
                label="垃圾中转站名称："
                prop="transferName"
                style="margin-bottom: 0px"
              >{{form.transferName}}</el-form-item>
              <el-form-item label="所属标段：" prop="bidId">{{form.bidName}}</el-form-item>
              <el-form-item
                label="开放时间："
                style="width: 100%"
              >{{form.openStartTime}} 至 {{form.openEndTime}}</el-form-item>
              <el-form-item label="垃圾中转站类型：">
                <el-select
                  disabled
                  v-model="form.transferType"
                  placeholder="未选择"
                  filterable
                >
                  <el-option
                    v-for="(item, idx) in $enums_hw.transferType.list"
                    :key="idx"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
              <el-form-item label="面积(m²)：">{{form.area}}</el-form-item>
              <el-form-item label="管理配员(位)：">{{form.demandWorker}}</el-form-item>
              <el-form-item label="每月水费(元)：">{{form.waterFeeMonthly}}</el-form-item>
              <el-form-item label="每月电费(元)：">{{form.electricityFeeMonthly}}</el-form-item>
              <!-- <el-form-item label="垃圾压缩箱(个)：">
                <el-input v-model="form.garbageCompactor" placeholder="请输入" maxlength="7"></el-input>
              </el-form-item>-->
              <el-form-item label="箱体品牌：">{{form.brand}}</el-form-item>
              <el-form-item label="产权：">
                <el-select
                  disabled
                  v-model="form.propertyRights"
                  placeholder="未选择"
                  filterable
                >
                  <el-option
                    v-for="(item, idx) in $enums_hw.propertyRights.list"
                    :key="idx"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
              <el-form-item label="出厂日期：">
                <el-date-picker
                  disabled
                  v-model="form.productionDate"
                  type="date"
                  value-format="timestamp"
                  placeholder="选择日期"
                ></el-date-picker>
              </el-form-item>

              <el-form-item label="单箱：">
                <el-checkbox disabled v-model="form.isSingle">是</el-checkbox>
              </el-form-item>
              <el-form-item label="含有公厕：">
                <el-checkbox disabled v-model="form.isContainedWc"></el-checkbox>
              </el-form-item>
              <el-form-item label="高压清洗类型：">
                <el-select
                  disabled
                  v-model="form.highPressureCleanType"
                  placeholder="未选择"
                  filterable
                >
                  <el-option
                    v-for="(item, idx) in $enums_hw.highPressureCleanType.list"
                    :key="idx"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
              <el-form-item label="用地手续是否完备：" style="width:100%">{{form.isCompletedFormalities}}</el-form-item>
              <el-form-item label="地址：" style="width:100%">{{form.address}}</el-form-item>
              <!-- 垃圾中转站附件-->
              <el-form-item label="垃圾中转站附件：" style="width: 100%">
                <ayl-image v-model="form.transferImageUrls"></ayl-image>
              </el-form-item>

              <!-- 垃圾压缩箱 -->
              <el-form-item label="垃圾压缩箱："></el-form-item>
              <div class="add-item" v-for="(tab,index) in form.compactorList" :key="index">
                <div class="add-item-main">
                  <el-form-item
                    label="编号："
                    :prop="'compactorList.'+index+'.garbageCompactorId'"
                    :rules="compactorListRules.garbageCompactorId"
                  >
                    {{tab.garbageCompactorId}}
                  </el-form-item>
                  <el-form-item label="类别：">
                    <el-select disabled v-model="tab.type" placeholder="未选择" filterable>
                      <el-option
                        v-for="(item, idx) in  $enums_hw.garbageType.list"
                        :key="idx"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                  </el-form-item>
                  <el-form-item label="容积：">
                    <el-select disabled v-model="tab.volume" placeholder="未选择" filterable>
                      <el-option
                        v-for="(item, idx) in $enums_hw.garbageVolumeType.list"
                        :key="idx"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                  </el-form-item>
                  <el-form-item label="颜色：">
                    <el-select disabled v-model="tab.color" placeholder="未选择" filterable>
                      <el-option
                        v-for="(item, idx) in $enums_hw.garbageColorType.list"
                        :key="idx"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                  </el-form-item>
                  <el-form-item label="品牌：">{{tab.brand}}</el-form-item>
                  <!-- <el-form-item label="所属项目组：">
                    <el-select
                      v-model="tab.projectGroupId"
                      placeholder="未选择"
                      filterable
                    >
                      <el-option
                        v-for="(item, idx) in partnerList"
                        :key="idx"
                        :label="item.partnerShorthand"
                        :value="item.partnerId"
                      />
                    </el-select>
                  </el-form-item>-->
                  <el-form-item label="购买日期：" prop="collection">
                    <el-date-picker
                      disabled
                      v-model="tab.buyDate"
                      type="date"
                      value-format="timestamp"
                      placeholder="选择日期"
                    ></el-date-picker>
                  </el-form-item>
                </div>
              </div>
            </el-form>
          </div>
        </div>
        <!-- 地图部分 -->
        <ayl-map v-model="mapData" icon="ljzzz"></ayl-map>
      </div>

      <div class="tac mt40px">
        <el-button type="danger" class="btn-danger" style="margin-left: 10px" @click="onClose">关闭</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import edit from "@/mixins/edit";
export default {
  mixins: [edit],
  data() {
    return {
      nav: [{ name: "编辑垃圾中转站" }],
      mapData: {
        //地图数据
        lnglat: null, //经纬度，数组，形如[118,36]
        scope: null //标段范围，经纬度点阵数组，形如[[118,36],[117,32],[116,113]]
      },
      form: {
        transferName: null, //垃圾中转站名称
        bidId: null, //所属标段
        cargoArrivalNumber: null,
        openStartTime: null,
        openEndTime: null,
        transferType: null, //垃圾中转类型
        area: null, //面积
        demandWorker: null, //管理配员
        waterFeeMonthly: null, //每月水费
        electricityFeeMonthly: null, //每月电费
        // transferType:null,//垃圾压缩箱数量
        brand: null, //箱体品牌
        productionDate: null, //出厂日期
        propertyRights: null, //产权
        isSingle: null, //是否单厢
        isContainedWc: null, //含有公厕
        isCompletedFormalities: null, //用地手续是否完备
        highPressureCleanType: null, //高压清洗类型
        address: null, //地址
        transferImageUrls: null, //附件
        compactorList: [
          //垃圾压缩箱
          {
            garbageCompactorId: null, //编号
            type: null, //类别
            volume: null, //容积
            color: null, //颜色
            brand: null, //品牌
            buyDate: null, //购买日期
            projectGroupId: null //所属项目组
          }
        ]
      },
      rules: {
        transferName: [
          {
            min: 1,
            max: 20,
            required: true,
            message: "请输入1-20位垃圾中转站名称",
            trigger: "blur"
          },
          { validator: this.$validator.isContainBlank }
        ],
        bidId: {
          required: true,
          message: "请选择所属标段",
          trigger: "change"
        }
      },
      compactorListRules: {
        garbageCompactorId: [
          {
            min: 1,
            max: 20,
            required: true,
            message: `请输入1-20位编号`,
            trigger: "blur"
          },
          { validator: this.$validator.isContainBlank }
        ]
      }
    };
  },
  methods: {
    async getInfo() {
      let self = this;
      let formData = await self.$api_hw.transferStationManagement_getById({
        id: self.$route.query.id
      });
      self.form = _fixData(formData);
      //修剪form的数据以方便编辑
      function _fixData(d) {
        //先检查key是否存在，不存在赋值null
        ["transferCoordinateScope", "bidScope"].forEach(key => {
          if (!d.hasOwnProperty(key)) {
            d[key] = null;
          }
        });
        //然后对key对应的值做处理
        for (let key in d) {
          switch (key) {
            case "isContainedWc":
            case "isSingle":
              d[key] = !!d[key];
              break;
            case "transferCoordinateScope":
              try {
                self.mapData.lnglat = JSON.parse(d[key]);
              } catch (e) {
                self.mapData.lnglat = null;
              }
              self.log("mapData.lnglat", self.mapData.lnglat);
              break;
            case "bidScope":
              try {
                self.mapData.scope = JSON.parse(d[key]);
              } catch (e) {
                self.mapData.scope = null;
              }
              break;
          }
        }
        return d;
      }
    }
  }
};
</script>

<style lang='sass' scoped>
.clearBox
  .content-box
    .content-main
      display: inline-block
      width: 100%
      &-left
        float: left
        width: 808px
      &-right
        float: left
        height: 777px
        width: calc(100% - 808px)
        background: #EDEDED
  .order
    overflow-x: visible !important
    &-type
      display: inline-block
      padding-right: 14px
      font-size: 14px
      font-family: Microsoft YaHei
      font-weight: 400
      color: rgba(102,102,102,1)
  .title
    width: 64px
    height: 17px
    font-size: 16px
    font-family: Microsoft YaHei
    font-weight: 400
    color: $-color-primary-3
    line-height: 18px
  .el-checkbox-group
    display: inline-block
  .map-popover
    position: absolute
    z-index: 2
    top: 12px
    right: 12px
    width: 180px
    height: 32px
    background: rgba(255,255,255,1)
    box-shadow: 0px 2px 8px 0px rgba(31,46,41,0.3)
    border-radius: 2px

  /deep/ .el-radio__input.is-checked .el-radio__inner
    background: $-color-primary-3
    border-color: $-color-primary-3
  /deep/ .el-radio__input.is-checked + .el-radio__label
    color: $-color-primary-3
  /deep/ .el-checkbox__input.is-checked .el-checkbox__inner
    background: $-color-primary-3
    border-color: $-color-primary-3
  /deep/ .el-checkbox__input.is-checked + .el-checkbox__label
    color: $-color-primary-3
  /deep/ .el-form-item
    width: 48%
  /deep/ .el-input--mini
    width: 170px
  .el-date-editor
    width: 170px
  .el-date-editor-bigwidth
    width: 350px
  .el-input-bigwidth
    width: 350px

.add-item
  width: 100%
  // height: 220px
  border: 1px solid rgba(225,225,225,1)
  border-radius: 2px
  margin-bottom: 20px
  .add-item-main
    margin: 10px 0

.add-btn
  text-align: center
  .btn
    width: 64px
    height: 28px
    border: 1px solid rgba(26,188,156,1)
    border-radius: 4px
    color: #1ABC9C
</style>
